import React from 'react'

function Classification() {
    return (
        <div className='classification'>
            <div class="container">
                <div class="row align-items-center my-5">
                    <p>The classification stuff will go here</p>
                </div>
            </div>
        </div>
    );
}

export default Classification;