import React from 'react'

function ReceiptOCR() {
    return (
        <div className='receipt-ocr'>
            <div class="container">
                <div class="row align-items-center my-5">
                    <p>The receipt optical character recognition (OCR) stuff will go here</p>
                </div>
            </div>
        </div>
    );
}

export default ReceiptOCR;